import { CategoryCount } from "@/hooks/useRetailCategories";
import prisma from "@/lib/db";
import { uuidSchema } from "@/lib/utils";

export const getAllCategories = async () => {
  try {
    const categories = await prisma.category.findMany({ orderBy: { sortOrder: "asc" } });
    return categories;
  } catch (error) {
    throw new Error("ブランド情報の取得に失敗しました");
  }
};

export const getChildCategories = async (categoryId: string | null | undefined = null) => {
  try {
    const parsedId = uuidSchema.safeParse(categoryId);
    if (!parsedId.success) {
      return null;
    }
    return await prisma.category.findMany({
      orderBy: { sortOrder: "asc" },
      where: {
        parentCategoryId: categoryId,
      },
    });
  } catch (error) {
    throw new Error(`カテゴリ（${categoryId}）の取得に失敗しました:${error}`);
  }
};

export const getCategoryById = async (categoryId: string | undefined) => {
  try {
    const parsedId = uuidSchema.safeParse(categoryId);
    if (!parsedId.success) {
      return null;
    }
    const category = await prisma.category.findUnique({
      where: {
        id: categoryId,
      },
    });

    return category;
  } catch (error) {
    throw new Error(`カテゴリ（${categoryId}）の取得に失敗しました:${error}`);
  }
};

export const getRetailCategories = async (): Promise<CategoryCount[]> => {
  try {
    const categories = await prisma.$queryRaw<any[]>`
    WITH RECURSIVE master AS (
      SELECT
        parent_category_id,
        id,
        name,
        kana_name,
        sort_order
      FROM
        categories
    ),
    retail_count AS (
      SELECT
        category_id,
        1 AS cnt
      FROM
        retail_products
      WHERE
        is_public = TRUE
        AND price > 0
      GROUP BY
        category_id
    ),
    adjacency_list AS (
      SELECT
        a.parent_category_id,
        a.id,
        a.name,
        a.kana_name,
        a.sort_order,
        b.cnt
      FROM
        master AS a
      LEFT JOIN retail_count AS b ON a.id = b.category_id
    ),
    category_tree AS (
      SELECT
        name,
        id,
        parent_category_id,
        cnt,
        kana_name,
        sort_order
      FROM
        adjacency_list
      WHERE
        cnt IS NOT NULL
      UNION ALL
      SELECT
        a.name,
        a.id,
        a.parent_category_id,
        coalesce(a.cnt,
          0) + COALESCE(sc.cnt,
          0) AS cnt,
        a.kana_name,
        a.sort_order
      FROM
        adjacency_list a
        JOIN category_tree sc ON a.id = sc.parent_category_id
    ),
    final AS (
      SELECT
        name,
        parent_category_id,
        id,
        sum(cnt) AS cnt,
        kana_name,
        sort_order
      FROM
        category_tree
      GROUP BY
        name,
        parent_category_id,
        id,
        kana_name,
        sort_order
      ORDER BY
        sort_order
    )
    SELECT
      *
    FROM
      final
    WHERE
      cnt > 0
    `;

    const mappedCategories = categories.map((category) => ({
      id: category.id,
      name: category.name,
      count: Number(category.cnt),
      kanaName: category.kana_name,
      parentCategoryId: category.parent_category_id,
      sortOrder: Number(category.sort_order),
    }));

    return mappedCategories;
  } catch (error) {
    throw new Error("ブランド情報の取得に失敗しました");
  }
};

export const getTradeInCategories = async (): Promise<CategoryCount[]> => {
  try {
    const categories = await prisma.$queryRaw<any[]>`
    WITH RECURSIVE master AS (
      SELECT
        parent_category_id,
        id,
        name,
        kana_name,
        sort_order
      FROM
        categories
    ),
    trade_in_count AS (
      SELECT
        category_id,
        1 AS cnt
      FROM
        trade_in_products
      WHERE
        is_public = TRUE
      GROUP BY
        category_id
    ),
    adjacency_list AS (
      SELECT
        a.parent_category_id,
        a.id,
        a.name,
        a.kana_name,
        a.sort_order,
        b.cnt
      FROM
        master AS a
      LEFT JOIN trade_in_count AS b ON a.id = b.category_id
    ),
    category_tree AS (
      SELECT
        name,
        id,
        parent_category_id,
        cnt,
        kana_name,
        sort_order
      FROM
        adjacency_list
      WHERE
        cnt IS NOT NULL
      UNION ALL
      SELECT
        a.name,
        a.id,
        a.parent_category_id,
        coalesce(a.cnt,
          0) + COALESCE(sc.cnt,
          0) AS cnt,
        a.kana_name,
        a.sort_order
      FROM
        adjacency_list a
        JOIN category_tree sc ON a.id = sc.parent_category_id
    ),
    final AS (
      SELECT
        name,
        parent_category_id,
        id,
        sum(cnt) AS cnt,
        kana_name,
        sort_order
      FROM
        category_tree
      GROUP BY
        name,
        parent_category_id,
        id,
        kana_name,
        sort_order
      ORDER BY
        sort_order
    )
    SELECT
      *
    FROM
      final
    WHERE
      cnt > 0
    `;

    const mappedCategories = categories.map((category) => ({
      id: category.id,
      name: category.name,
      count: Number(category.cnt),
      kanaName: category.kana_name,
      parentCategoryId: category.parent_category_id,
      sortOrder: Number(category.sort_order),
    }));
    return mappedCategories;
  } catch (error) {
    throw new Error("ブランド情報の取得に失敗しました");
  }
};
