import { CategoryCount } from "@/hooks/useRetailCategories";
import { getTradeInCategories } from "@/lib/db/category";
import { conditionMap } from "@/shared/constants/conditionMap";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";
import z from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getUrl = () => {
  const url = process.env.NEXT_PUBLIC_URL;

  return url || `http://localhost:${process.env.PORT || 3000}`;
};

export function getRetailConditionLabel(condition: keyof typeof conditionMap) {
  return conditionMap[condition];
}

export function getTradeInConditionLabelByJunkFlag(isJunk: boolean | null) {
  if (isJunk === null) {
    return "-";
  }

  return isJunk ? "ジャンク品" : "正常動作品";
}

export function getTradeInConditionLabelByCategory(condition: "JUNK" | "USED") {
  return condition === "JUNK" ? "ジャンク品" : "正常動作品";
}

/**
 * yyyy, mm, ddの組み合わせから有効な日付か判定する
 * NOTE: js-Dateの仕様上、"0231"(2月31日)のような表記も有効な日付として"0302"（3月2日）に変換される
 * そのため、yyyymmddの組み合わせと生成されたDateで年月日が一致しているかどうかをもとに判定
 * @param value 年月日の文字列 例：「yyyy: 1999, mm: 01, dd: 01」
 * @returns boolean
 */
export const isValidDate = (yyyy: string, mm: string, dd: string): boolean => {
  const date_ = new Date(parseInt(yyyy, 10), parseInt(mm, 10) - 1, parseInt(dd, 10));

  return (
    date_.getFullYear() === parseInt(yyyy, 10) &&
    date_.getMonth() === parseInt(mm, 10) - 1 &&
    date_.getDate() === parseInt(dd, 10)
  );
};

export const getCurrentDateTimeJST = (): string => {
  return new Date(Date.now()).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
};

export async function getAncestorCategoryName(categoryId: string | null): Promise<string | null> {
  const categories = await getTradeInCategories();
  let currentCategoryId = categoryId;

  while (currentCategoryId) {
    const category = categories.find(
      (category: CategoryCount) => category.id === currentCategoryId,
    );
    if (category) {
      if (category.parentCategoryId === null) {
        return category.name;
      }
      currentCategoryId = category.parentCategoryId;
    } else {
      break;
    }
  }

  return null;
}

// 網羅性チェック用のエラークラス
// ref: https://typescriptbook.jp/reference/statements/never
export class ExhaustiveError extends Error {
  constructor(value: never, message = `Unsupported type: ${value}`) {
    super(message);
  }
}

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
export const uuidSchema = z.string().regex(uuidRegex);

export const createCompressUUID = () => {
  const uuid = uuidv4();
  // ハイフンを除去
  const uuidWithoutHyphens = uuid.replace(/-/g, "");

  // 16進数文字列をバッファに変換
  const buffer = Buffer.from(uuidWithoutHyphens, "hex");

  // Base64エンコードし、URLセーフな文字列に変換し、パディングを削除
  const compressedUUID = buffer
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return compressedUUID;
};
